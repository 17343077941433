<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
    
    <div class="main_title">
        <span>Communication</span>
    </div>

    <div class="container mt-3">
        <div class="accordion" id="accordionExample">
            <!-- Section A -->
            <div class="card">
                <div class="card-header" id="headingA">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button" type="button" data-toggle="collapse" data-target="#collapseA" aria-expanded="true" aria-controls="collapseA">
                            Employee Benefits Manual – Text & Voice
                        </button>
                    </h2>
                </div>
                <div id="collapseA" class="collapse show" aria-labelledby="headingA" data-parent="#accordionExample">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-6">
                                <p style="margin-top: 30px;">The Employee Benefits Insurance Manual is your comprehensive guide to navigating employee benefits, available in both text and voice formats for convenience. We provide tools which help make this great manual easy and effortless.</p>
                                <h4 class="my-3">Key Sections:</h4>
                                <ul>
                                    <li>Coverage Options:
                                        <ul>
                                            <li>Health Insurance: Overview of medical, dental, and vision plans.</li>
                                            <li>Disability Insurance: Information on short-term and long-term coverage.</li>
                                            <li>Life Insurance: Details on policies for employees and dependents.</li>
                                            <li>Retirement Plans: Description of 401(k) options and pension plans.</li>
                                            <li>Wellness Programs: Initiatives to promote physical and mental health.</li>
                                        </ul>
                                    </li>
                                    <li>Enrolment Process:
                                        <ul>
                                            <li>Step-by-Step Guide: Clear instructions for enrolling in benefits.</li>
                                            <li>Important Deadlines: Key dates for open enrolment and changes.</li>
                                        </ul>
                                    </li>
                                    <li>FAQs: Answers to common questions about claims, coverage, and eligibility.</li>
                                    <li>Key Contacts:
                                        <ul>
                                            <li>HR Department: Contact information for benefits-related inquiries.</li>
                                            <li>Insurance Providers: Direct lines for specific policy questions.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>Access the manual to ensure you fully understand your employee benefits!</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        
            <!-- Section B -->
            <div class="card">
                <div class="card-header" id="headingB">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseB" aria-expanded="false" aria-controls="collapseB">
                            Voice Version
                        </button>
                    </h2>
                </div>
                <div id="collapseB" class="collapse" aria-labelledby="headingB" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="my-3 mt-4">Accessing the Voice Manual</h4>
                                <ul>
                                    <li>Call the Benefits Hotline: Dial [insert phone number] to listen to an audio guide on employee benefits, or access it via the provided link.</li>
                                    <li>Voice Commands: Use simple commands to navigate topics, such as:
                                        <ul>
                                            <li>“Tell me about health insurance.”</li>
                                            <li>“How do I enrol in benefits?”</li>
                                            <li>“What are my key contacts?”</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>Access the voice manual for convenient information on your employee benefits!</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="https://images.pexels.com/photos/8198067/pexels-photo-8198067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        
            <!-- Section C -->
            <div class="card">
                <div class="card-header" id="headingC">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseC" aria-expanded="false" aria-controls="collapseC">
                            Customized Communication for Employee Benefits
                        </button>
                    </h2>
                </div>
                <div id="collapseC" class="collapse" aria-labelledby="headingC" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="my-3 mt-4">Effective communication about employee benefits insurance is essential for helping employees make informed choices. Our tailored strategy includes:</h4>
                                <h5>Personalized Messaging</h5>
                                <ul>
                                    <li>Audience Segmentation: Customize messages for different employee groups based on demographics and tenure.</li>
                                    <li>Targeted Content: Address specific needs, such as family coverage or retirement planning.</li>
                                </ul>
                                <h5>Diverse Communication Channels</h5>
                                <ul>
                                    <li>Email Campaigns: Send segmented newsletters with updates and reminders.</li>
                                    <li>Intranet Resources: Create a dedicated benefits section for easy access to documents and FAQs.</li>
                                    <li>Workshops and Webinars: Host interactive sessions for real-time learning and feedback.</li>
                                </ul>
                                <h5>Engaging Materials</h5>
                                <ul>
                                    <li>Infographics: Develop visual aids to simplify complex information.</li>
                                    <li>Interactive Tools: Provide decision-making tools to assess coverage needs.</li>
                                </ul>
                                <h5>Feedback and Adaptation</h5>
                                <ul>
                                    <li>Surveys and Polls: Collect employee feedback to improve communication.</li>
                                    <li>Focus Groups: Gather deeper insights for better strategies.</li>
                                </ul>
                                <h5>Ongoing Education</h5>
                                <ul>
                                    <li>Comprehensive FAQs: Address common concerns about benefits.</li>
                                    <li>Regular Updates: Inform employees of changes through various channels.</li>
                                </ul>
                                <h5>Accessible Support</h5>
                                <ul>
                                    <li>Dedicated Help Desk: Provide multiple contact methods for assistance.</li>
                                    <li>24/7 Resources: Offer online access to benefits information and support.</li>
                                </ul>
                                <p>This customized communication strategy enhances engagement and clarity around employee benefits insurance.</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="https://images.pexels.com/photos/821754/pexels-photo-821754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        
            <!-- Section D -->
            <div class="card">
                <div class="card-header" id="headingD">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseD" aria-expanded="false" aria-controls="collapseD">
                            Enrolment Process Communication
                        </button>
                    </h2>
                </div>
                <div id="collapseD" class="collapse" aria-labelledby="headingD" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="my-3 mt-4">Clear communication about the employee benefits enrolment process is essential for helping employees understand their options and deadlines. Our structured approach includes:</h4>
                                <ul>
                                    <li>Why Insurance Matters: Highlight the importance of employee benefits.</li>
                                    <li>Enrolment Process Overview: Provide a clear summary of the steps involved.</li>
                                    <li>Your Options for This Year: Outline available benefits options.</li>
                                    <li>Enrolment Period: Specify key dates for enrolment.</li>
                                    <li>Step-by-Step Enrolment Instructions: Offer detailed guidance on how to enrol.</li>
                                    <li>Important Reminders: Share critical reminders to keep employees on track.</li>
                                    <li>Support and Assistance: Provide contact information for help.</li>
                                    <li>Informational Webinar: Host a webinar to explain the process and answer questions.</li>
                                </ul>
                                <p>This comprehensive communication strategy ensures employees are well-informed and prepared for the enrolment process.</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="https://images.pexels.com/photos/7821684/pexels-photo-7821684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        
            <!-- Section E -->
            <div class="card">
                <div class="card-header" id="headingE">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseE" aria-expanded="false" aria-controls="collapseE">
                            Audio & Video Tool for Employee Benefits Communication
                        </button>
                    </h2>
                </div>
                <div id="collapseE" class="collapse" aria-labelledby="headingE" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="my-3 mt-4">Enhancing communication about employee benefits insurance through audio and video tools can significantly boost understanding and engagement. Recommended strategies include:</h4>
                                <ul>
                                    <li>Webinars: Live sessions for real-time interaction and Q&A.</li>
                                    <li>Video Tutorials: Short videos explaining key benefits and processes.</li>
                                    <li>Podcasts: Audio content for on-the-go learning about benefits topics.</li>
                                    <li>Interactive E-Learning Modules: Engaging online courses that allow self-paced learning.</li>
                                    <li>FAQ Videos: Visual answers to common questions about benefits.</li>
                                    <li>Visual Presentations: Eye-catching slideshows summarizing important information.</li>
                                    <li>Internal Messaging Apps: Platforms for sharing audio and video content easily within the organization.</li>
                                </ul>
                                <p>These tools create a more interactive and informative experience, helping employees better understand their benefits options.</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="https://images.pexels.com/photos/1595391/pexels-photo-1595391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>            
</div>        
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->