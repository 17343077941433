<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>                 
    
    <div class="main_title">
        <span>Wellbeing</span>
    </div>

    <div class="container mt-3">
    <div class="accordion" id="benefitsAccordion">

        <div class="card">
            <div class="card-header" id="headingHRA">
                <h2 class="mb-0">
                    <button class="btn btn-link accordion-button" type="button" data-toggle="collapse" data-target="#collapseHRA" aria-expanded="true" aria-controls="collapseHRA">
                        Health Reimbursement Arrangement (HRA)
                    </button>
                </h2>
            </div>

            <div id="collapseHRA" class="collapse show" aria-labelledby="headingHRA" data-parent="#benefitsAccordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <p style="margin-top: 30px;">An HRA is a health benefit plan that allows employers to reimburse employees for qualified medical expenses. It provides a range of key features and benefits:</p>
                            <h4 class="my-3">Key Features:</h4>
                            <ul>
                                <li><strong>Employer-Funded:</strong> Only employers contribute; employees do not add funds.</li>
                                <li><strong>Reimbursement Basis:</strong> Employees submit claims for qualified expenses and are reimbursed up to the employer-set limit.</li>
                                <li><strong>Qualified Expenses:</strong> Includes deductibles, copayments, prescription medications, and other IRS-defined medical costs.</li>
                                <li><strong>Tax Advantages:</strong> Employer contributions are tax-deductible, and reimbursements are typically tax-free for employees.</li>
                                <li><strong>Unused Funds:</strong> Depending on the plan, unused funds may roll over to the next year or be subject to a "use-it-or-lose-it" rule.</li>
                                <li><strong>Plan Design Flexibility:</strong> Employers can tailor HRAs to their benefits strategy, defining eligible expenses and contribution limits.</li>
                            </ul>
                            <h4 class="my-3">Benefits:</h4>
                            <ul>
                                <li><strong>Cost Control:</strong> Helps employers manage healthcare costs while offering valuable employee benefits.</li>
                                <li><strong>Attractive Benefit Offering:</strong> Enhances the overall benefits package, aiding in talent attraction and retention.</li>
                                <li><strong>Encouragement of Healthcare Utilization:</strong> Promotes the use of health benefits, contributing to better employee health outcomes.</li>
                                <li><strong>Flexibility:</strong> HRAs can be customized to meet the unique needs of both the workforce and the organization.</li>
                            </ul>
                            <p>By leveraging HRAs, employers can provide meaningful health benefits while maintaining control over costs.</p>
                        </div>
                        <div class="col-sm-6">
                            <img src="https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header" id="headingWellnessTips">
                <h2 class="mb-0">
                    <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseWellnessTips" aria-expanded="false" aria-controls="collapseWellnessTips">
                        Wellness Tips for Employees
                    </button>
                </h2>
            </div>
            <div id="collapseWellnessTips" class="collapse" aria-labelledby="headingWellnessTips" data-parent="#benefitsAccordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <p style="margin-top: 30px;">Wellness tips are practical strategies designed to enhance overall health and well-being in the workplace. They focus on various aspects of physical, mental, and emotional health to improve quality of life and prevent illness. Here are key topics to raise awareness among employees:</p>
                            <ul>
                                <li><strong>Stay Hydrated:</strong> Drink plenty of water throughout the day.</li>
                                <li><strong>Eat a Balanced Diet:</strong> Focus on nutritious foods for better energy and health.</li>
                                <li><strong>Regular Exercise:</strong> Incorporate physical activity into your daily routine.</li>
                                <li><strong>Prioritize Sleep:</strong> Ensure adequate rest for optimal functioning.</li>
                                <li><strong>Practice Mindfulness and Meditation:</strong> Engage in techniques to reduce stress.</li>
                                <li><strong>Take Breaks:</strong> Step away from work periodically to recharge.</li>
                                <li><strong>Connect with Others:</strong> Foster relationships for emotional support.</li>
                                <li><strong>Limit Screen Time:</strong> Take breaks from digital devices to reduce strain.</li>
                                <li><strong>Schedule Regular Health Check-ups:</strong> Stay proactive about your health.</li>
                                <li><strong>Engage in Hobbies:</strong> Pursue interests outside of work for relaxation.</li>
                            </ul>
                            <p>By promoting these wellness tips, employers can help create a healthier, more engaged workforce.</p>
                        </div>
                        <div class="col-sm-6">
                            <img src="https://images.pexels.com/photos/2821823/pexels-photo-2821823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header" id="headingWellnessPartnerManagement">
                <h2 class="mb-0">
                    <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseWellnessPartnerManagement" aria-expanded="false" aria-controls="collapseWellnessPartnerManagement">
                        Wellness Partner Management
                    </button>
                </h2>
            </div>
            <div id="collapseWellnessPartnerManagement" class="collapse" aria-labelledby="headingWellnessPartnerManagement" data-parent="#benefitsAccordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <p style="margin-top: 30px;">Streamline your employee wellness initiatives with our comprehensive Wellness Partner Management services. We coordinate logistics with wellness vendors, delivering tailored solutions that cater to your workforce's unique needs. By reducing the administrative burden on HR, we enable teams to concentrate on strategic initiatives while ensuring consistent communication and effective program monitoring. Our expert support helps enhance employee engagement and satisfaction, fostering a healthier workplace culture. Let us take the stress out of wellness management and empower your HR team to thrive!</p>
                        </div>
                        <div class="col-sm-6">
                            <img src="https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" style="display: block; margin: auto; margin-top:30px; width: 100%;" /> 
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

    </div>
    </div>
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->