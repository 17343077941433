<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>Vision & Mission</span>
    </div>

    <section class="vision-mission-section my-5">
        <div class="container vision-mission-container">
            <div class="row">                            
                <!-- Vision Card -->
                <div class="col-lg-6 col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">Vision</h2>
                            <p>At NeoDigit, our vision is to transform the insurance industry with cutting-edge technology solutions, empowering businesses to excel in a rapidly evolving landscape.</p>
                            <p>We envision a future where:</p>
                            <ul>
                                <li><strong>Innovation Meets Expertise:</strong> Delivering exceptional support through advanced solutions.</li>
                                <li><strong>Growth-Driven Solutions:</strong> Providing timely tools to propel business expansion.</li>
                                <li><strong>Seamless Operations:</strong> Ensuring smooth processes with proactive monitoring.</li>
                                <li><strong>Tailored Guidance:</strong> Fostering excellence through personalized training and support.</li>
                                <li><strong>Adaptive Technology:</strong> Evolving with your needs to keep you ahead in a dynamic landscape.</li>
                            </ul>
                            <p>Join us in shaping the future of insurance</p>
                        </div>
                    </div>
                </div>

                <!-- Mission Card -->
                <div class="col-lg-6 col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">Mission</h2>
                            <p>At Neodigit, our mission is to empower insurance professionals with innovative technology solutions that simplify processes, enhance efficiency, and deliver unparalleled value. We are committed to transforming the insurance experience for all stakeholders by providing tools that drive growth and foster meaningful connections.</p>
                            <!-- <p>With a well-established digital footprint, you can:</p> -->
                            <!-- <ul>
                                <li><strong>Operate 24/7:</strong> Generate business and connect with clients around the clock, every day of the year.</li>
                                <li><strong>Overcome Geographical Limits:</strong> Reach potential clients beyond your local area, expanding your market.</li>
                                <li><strong>Enhance Visibility:</strong> Make it easier for clients to find and engage with your services.</li>
                            </ul> -->
                            <p>Harness the power of technology & the internet to boost your business and stay competitive.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->
