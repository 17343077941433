import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './commonComponents/navbar/navbar.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { ChartModule } from 'angular-highcharts';
import { HomeComponent } from './home/home.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SidebarComponent } from './commonComponents/sidebar/sidebar.component';
import { FooterComponent } from './commonComponents/footer/footer.component';
import { LogoutComponent } from './commonComponents/logout/logout.component';
import { AboutCompanyComponent } from './website/about-company/about-company.component';
import { LeadershipTeamComponent } from './website/leadership-team/leadership-team.component';
import { WhyNeodigitComponent } from './website/why-neodigit/why-neodigit.component';
import { VisionMissionComponent } from './website/vision-mission/vision-mission.component';
import { EbPortalComponent } from './website/eb-portal/eb-portal.component';
import { HealthBenefitsToolComponent } from './website/health-benefits-tool/health-benefits-tool.component';
import { BenefitsConsultingComponent } from './website/benefits-consulting/benefits-consulting.component';
import { AutomationComponent } from './website/automation/automation.component';
import { CommunicationComponent } from './website/communication/communication.component';
import { WellbeingComponent } from './website/wellbeing/wellbeing.component';
import { CustomizedSolutionComponent } from './website/customized-solution/customized-solution.component';
import { WebFooterComponent } from './website/web-footer/web-footer.component';
import { WebMenuBarComponent } from './website/web-menu-bar/web-menu-bar.component';
import { AffinityComponent } from './website/affinity/affinity.component';
import { ScheduleDemoComponent } from './website/schedule-demo/schedule-demo.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,    
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    SidebarComponent,
    FooterComponent,
    LogoutComponent,
    AboutCompanyComponent,
    LeadershipTeamComponent,
    WhyNeodigitComponent,
    VisionMissionComponent,
    EbPortalComponent,
    HealthBenefitsToolComponent,
    BenefitsConsultingComponent,
    AutomationComponent,
    CommunicationComponent,
    WellbeingComponent,
    CustomizedSolutionComponent,
    WebFooterComponent,
    WebMenuBarComponent,
    AffinityComponent,
    ScheduleDemoComponent
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    NgSelectModule,
    FormsModule,
    NgxLoaderModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,    
    NgxSliderModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
