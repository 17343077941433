<!-- footer -->
<footer class="footer-bg footer-p" style="background-color: #fff;">
    <div class="overly"><img src="../../assets/website/an-bg/footer-bg.png" alt="rest"></div>
    <div class="footer-top pb-30" style="background-color: #ECF1FA;">
        <div class="container">
            <div class="row justify-content-between">

                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="flog mb-35">
                            <a href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                        <div class="footer-text mb-20">
                            <p>NeoDigit is backed by a team of industry veterans and technological experts with over 21 years of combined experience in insurance and technology.</p>
                        </div>
                        <div class="footer-social">
                            <a href="https://www.facebook.com/profile.php?id=61562581990701" target="_blank" title="Facebook"><i class="fab fa-facebook"></i></a>
                            <a href="https://x.com/Neodigit_Tech" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/in/neo-digit-a3a039319/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BPrmU%2BfuARyabCOiCZsCBNA%3D%3D" target="_blank" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                            <a href="https://www.instagram.com/neo_digit/#" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a>
                            <a href="#" target="_blank" title="Whatsapp"><img src="../../../assets/website/whatsapp.png" style="width: 100%;" /></a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>About Us Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a routerLink="/about-company"><i class="fas fa-chevron-right"></i> About Company</a></li>
                                <li><a routerLink="/leadership-team"><i class="fas fa-chevron-right"></i> Leadership Team</a></li>
                                <li><a routerLink="/why-neodigit"><i class="fas fa-chevron-right"></i> Why NeoDigit?</a></li>
                                <li><a routerLink="/vision-mission"><i class="fas fa-chevron-right"></i> Vision & Mission</a></li>                                    
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Other Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <ul>
                                    <li><a routerLink="/home"><i class="fas fa-chevron-right"></i> Home</a></li>                                                                                                        
                                    <li><a routerLink="/schedule-demo"><i class="fas fa-chevron-right"></i> Schedule A Demo</a></li>
                                </ul>                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Our Services</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <ul>                                                                                                        
                                    <li><a routerLink="/eb-portal"><i class="fas fa-chevron-right"></i> EB Portal</a></li>
                                    <li><a routerLink="/health-benefits-tool"><i class="fas fa-chevron-right"></i> Health & Benefits Tool</a></li>
                                    <li><a routerLink="/benefits-consulting"><i class="fas fa-chevron-right"></i> Benefits Consulting</a></li>
                                    <li><a routerLink="/automation"><i class="fas fa-chevron-right"></i> Automation (Service & Operations)</a></li>
                                    <li><a routerLink="/communication"><i class="fas fa-chevron-right"></i> Communication</a></li>
                                    <li><a routerLink="/wellbeing"><i class="fas fa-chevron-right"></i> Wellbeing</a></li>
                                    <li><a routerLink="/affinity"><i class="fas fa-chevron-right"></i> Affinity</a></li>
                                    <li><a routerLink="/customized-solution"><i class="fas fa-chevron-right"></i> Customized Solution</a></li>
                                </ul>                                
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="footer-link">
                            <div class="f-contact">
                                <ul>
                                    <li>
                                        <img src="../../assets/img/common icons/to-do-list.png" style="width: 40px; height: 40px;" />
                                        <span>9999422119</span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/clientuser.png" style="width: 40px; height: 40px;" />
                                        <span><a href="mailto:info@neodigit.in">info@neodigit.in</a></span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/product.png" style="width: 40px; height: 40px;" />                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="copyright-text text-center">
                        <div class="container text-center">
                            <small class="text-black">Copyright &copy; {{currentYear}} NeoDigit | All rights reserved</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>