import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { ScheduleDemoComponent } from './website/schedule-demo/schedule-demo.component';
import { AboutCompanyComponent } from './website/about-company/about-company.component';
import { LeadershipTeamComponent } from './website/leadership-team/leadership-team.component';
import { WhyNeodigitComponent } from './website/why-neodigit/why-neodigit.component';
import { VisionMissionComponent } from './website/vision-mission/vision-mission.component';
import { EbPortalComponent } from './website/eb-portal/eb-portal.component';
import { HealthBenefitsToolComponent } from './website/health-benefits-tool/health-benefits-tool.component';
import { BenefitsConsultingComponent } from './website/benefits-consulting/benefits-consulting.component';
import { AutomationComponent } from './website/automation/automation.component';
import { CommunicationComponent } from './website/communication/communication.component';
import { WellbeingComponent } from './website/wellbeing/wellbeing.component';
import { CustomizedSolutionComponent } from './website/customized-solution/customized-solution.component';
import { AffinityComponent } from './website/affinity/affinity.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },  
  { path: 'home', component: HomeComponent },  
  { path: 'about-us', component: AboutUsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'about-company', component: AboutCompanyComponent },
  { path: 'leadership-team', component: LeadershipTeamComponent },
  { path: 'why-neodigit', component: WhyNeodigitComponent },
  { path: 'vision-mission', component: VisionMissionComponent },
  { path: 'eb-portal', component: EbPortalComponent },
  { path: 'health-benefits-tool', component: HealthBenefitsToolComponent },
  { path: 'benefits-consulting', component: BenefitsConsultingComponent },
  { path: 'automation', component: AutomationComponent },
  { path: 'communication', component: CommunicationComponent },
  { path: 'wellbeing', component: WellbeingComponent },
  { path: 'customized-solution', component: CustomizedSolutionComponent },
  { path: 'affinity', component: AffinityComponent },
  { path: 'schedule-demo', component: ScheduleDemoComponent}    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
