<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">

    <!-- <div  *ngIf="user_role == 'brokeradmin'">

      <div *ngFor="let item of json[0]['data']['module_info']; let i = index">
        <li class="nav-item"  style="margin-top: 20px;" [ngClass]="{'active': url === item['module_url']}" *ngIf="item['child'].length == 0">
          <a class="nav-link" routerLink="{{item['module_url']}}">
            <i class="mdi {{item['module_icon']}} menu-icon"></i>
            <span class="menu-title">{{item['module_name']}}</span>
          </a>
        </li>
      </div>

      <div *ngFor="let subitem of json[0]['data']['module_info']; let i = index" class="my-3">
        <li class="nav-item" *ngIf="subitem['child'].length > 0">
          <a class="nav-link" data-bs-toggle="collapse" href="#{{subitem['module_url']}}" aria-expanded="false">
            <i class="menu-icon mdi {{subitem['module_icon']}}"></i>
            <span class="menu-title">{{subitem['module_name']}}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="{{subitem['module_url']}}">
            <ul class="nav flex-column sub-menu">
              <div *ngFor="let submenu of subitem['child']; let j = index">
                <li class="nav-item" [ngClass]="{'active': url === submenu['module_url']}">
                  <a routerLink="{{submenu['module_url']}}" class="nav-link">{{submenu['module_name']}}</a>
                </li>
              </div>
            </ul>
          </div>
        </li>

      </div>

    </div>  -->    


    <!---------------------- Broker Admin --------------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'brokeradmin'&& broker_dashboard == 1 " [ngClass]="{'active': url === '/broker-dashboard'}">
      <a routerLink="/broker-dashboard" id="broker-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_manage_master == 1" [ngClass]="{'active': url === '/broker-manage-master'}">
      <a routerLink="/broker-manage-master" class="nav-link">
        <i class="mdi mdi-human-child menu-icon"></i>
        <span class="menu-title">Manage Client</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_user_management == 1" [ngClass]="{'active': url === '/broker-manage-master-employer/user-management'}">
      <a routerLink="/broker-manage-master-employer/user-management" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>
    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/policies'}">
      <a routerLink="/policies" class="nav-link">
        <i class="mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policies</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && management == '1'">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isMgmtOpen" (click)="toggleMgmtCollapse()" href="#managelist" aria-expanded="false" aria-controls="managelist">
        <i class="menu-icon mdi mdi-settings"></i>
        <span class="menu-title">Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isMgmtOpen, 'show': isMgmtOpen}" id="managelist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-theme'}" *ngIf="manage_theme == 1">
            <a routerLink="/admin-manage-theme" class="nav-link"> 
              <!-- <img src="../../../assets/img/common icons/theme.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-theme-light-dark menu-icon"></i>
              <span class="menu-title">Manage Theme</span>
            </a>
          </li>
          
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="tpa_details == 1">
            <a routerLink="/admin-tpa-details" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_details.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-settings-box menu-icon"></i>
              <span class="menu-title">TPA Details</span>
            </a>
          </li>

          <li class="nav-item" [ngClass]="{'active': url === '/admin-insurance-company-insurer'}" *ngIf="insurance_company_insurer == 1">
            <a routerLink="/admin-insurance-company-insurer" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/master_broker.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-multiple menu-icon"></i>
              <span class="menu-title">Insurance Company</span>
            </a>
          </li>

          <li class="nav-item" [ngClass]="{'active': url === '/admin-document-type'}" *ngIf="document_type == 1">
            <a routerLink="/admin-document-type" class="nav-link">
              <i class="mdi mdi-file-plus menu-icon"></i>
              <span class="menu-title">Document Type</span>
            </a>
          </li>

          <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_product_feature_master == 1" [ngClass]="{'active': url === '/product-feature-master'}">
            <a routerLink="/product-feature-master" class="nav-link">
              <i class="mdi mdi-collage menu-icon"></i>
              <span class="menu-title">Product Feature Master</span>
            </a>
          </li>

        </ul>
      </div>
    </li>

    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/admin-manage-theme'}">
      <a routerLink="/admin-manage-theme" class="nav-link">
        <i class="mdi mdi-theme-light-dark menu-icon"></i>
        <span class="menu-title">Manage Theme</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && policies_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isPoliciesOpen" (click)="togglePoliciesCollapse()" href="#policies" aria-expanded="false" aria-controls="policies">
        <i class="menu-icon mdi mdi-snowflake menu-icon"></i>
        <span class="menu-title">Policy</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isPoliciesOpen, 'show': isPoliciesOpen}" id="policies">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/policies'}" *ngIf="create_policy == 1">
            <a routerLink="/policies" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/create_policy.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-pencil-box menu-icon"></i>
              <span class="menu-title">Create Policy</span>             
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/listpolicies'}" *ngIf="listpolicies == 1">
            <a routerLink="/listpolicies" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/policy_list.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">List Policies</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/member-upload'}" *ngIf="member_upload == 1">
            <a routerLink="/member-upload" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/member_upload.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-plus menu-icon"></i>
              <span class="menu-title">Member Upload</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/nominee-upload'}" *ngIf="nominee_upload == 1">
            <a routerLink="/nominee-upload" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/nomine_upload.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-upload menu-icon"></i>
              <span class="menu-title">Nominee Upload</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/endoresement-request'}" *ngIf="endorsement_request == 1">
            <a routerLink="/endoresement-request" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/endorsement_request.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-format-list-checks menu-icon"></i>
              <span class="menu-title">Endoresement Request</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/flex-configurator'}" *ngIf="flex_configurator == 1">
            <a routerLink="/flex-configurator" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/flex_configurator.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-hexagon-multiple menu-icon"></i>
              <span class="menu-title">Flex Plan Configurator</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/upload-policy-coverage'}" *ngIf="flex_configurator == 1">
            <a routerLink="/upload-policy-coverage" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/policy_lockin.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-lock-reset menu-icon"></i>
              <span class="menu-title">Policy Lock-in </span>
            </a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'active': url === '/admin-insurance-company-insurer'}" *ngIf="insurance_company_insurer == 1">
            <a routerLink="/admin-insurance-company-insurer" class="nav-link">
              <img src="../../../assets/img/common icons/tpa_details.png" alt="Icon" class="menuicon">
              <span class="menu-title">Insurance Company</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="tpa_details == 1">
            <a routerLink="/admin-tpa-details" class="nav-link">
              <img src="../../../assets/img/common icons/tpa_details.png" alt="Icon" class="menuicon">
              <span class="menu-title">TPA Details</span>
            </a>
          </li> -->
        </ul>
      </div>
    </li>


    <!------------------ Broker User -------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'brokeruser' && brokeruser_dashboard== 1" [ngClass]="{'active': url === '/brokeruser-dashboard'}">
      <a class="nav-link" routerLink="/brokeruser-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>


    <!--------------- Claims Executive User ---------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'claimsexecutiveuser' && claimsexecutiveuser_dashboard == '1'" [ngClass]="{'active': url === '/claimsexecutiveuser-dashboard'}">
      <a routerLink="/claimsexecutiveuser-dashboard" id="claimsexecutiveuser-dashboard" class="nav-link">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>    



    <!------------- Employer Admin & Broker Admin ----------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeradmin' && employer_dashboard == 1" [ngClass]="{'active': url === '/employer-dashboard'}">
      <a class="nav-link" routerLink="/employer-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin' && employee_user_management == 1" [ngClass]="{'active': url === '/employee-user-management'}">
      <a class="nav-link" routerLink="/employee-user-management">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">User Management</span>
      </a>
    </li>
        
    <li class="nav-item" *ngIf="(user_role == 'brokeradmin' || user_role == 'employeradmin' || user_role == 'claimsexecutiveuser') && claim_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isClaimsOpen" (click)="toggleClaimsCollapse()" href="#claims" aria-expanded="false" aria-controls="claims">
        <i class="menu-icon mdi mdi-clipboard-check"></i>
        <span class="menu-title">Claims</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isClaimsOpen, 'show': isClaimsOpen}" id="claims">
        <ul class="nav flex-column sub-menu">

          <li *ngIf="claim_intimation == 1" class="nav-item" [ngClass]="{'active': url === '/broker-intimate-claims'}">
            <a routerLink="/broker-intimate-claims" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/intimate_claim.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-note-plus menu-icon"></i>
              <span class="menu-title">Intimate Claim</span>             
            </a>
          </li>

          <li *ngIf="claim_portal == 1" class="nav-item" [ngClass]="{'active': url === '/portal-claims'}">
            <a class="nav-link" routerLink="/portal-claims">
              <i class="mdi mdi-server menu-icon"></i>
              <span class="menu-title">Portal Claims</span>
            </a>
          </li>

          <li *ngIf="overall_claims == 1" class="nav-item" [ngClass]="{'active': url === '/overall-claims'}">
            <a class="nav-link" routerLink="/overall-claims">
              <i class="mdi mdi-receipt menu-icon"></i>
              <span class="menu-title">Overall Claims</span>
            </a>
          </li>

          

          <!-- <li class="nav-item" [ngClass]="{'active': url === '/claim-intimation'}" *ngIf="executive_user_claim_intimation == '1'">
            <a class="nav-link" (click)="checkclaim()" style="cursor: pointer;">Claims Dashboard </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/claim-status'}">
            <a routerLink="/claim-status" class="nav-link">Claim Status Master</a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/broker-insurance-company'}">
            <a routerLink="/broker-insurance-company" class="nav-link">Insurance Company</a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}">
            <a routerLink="/admin-tpa-details" class="nav-link">TPA Details</a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-theme'}">
            <a routerLink="/admin-manage-theme" class="nav-link">Manage Theme</a>
          </li>        -->
        </ul>
      </div>
    </li>    

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && reports == 1" [ngClass]="{'active': url === '/'}">
      <a routerLink="/broker-dashboard" class="nav-link">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Reports</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin' && employer_reports == 1" [ngClass]="{'active': url === '/'}">
      <a routerLink="/broker-dashboard" class="nav-link">
        <i class="mdi mdi-file-document menu-icon"></i>
        <span class="menu-title">Reports</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin' && networks == 1" [ngClass]="{'active': url === '/networks'}">
      <a class="nav-link" routerLink="/networks">
        <i class="mdi mdi-plus-network menu-icon"></i>
        <span class="menu-title">Network Hospital</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeradmin'" [ngClass]="{'active': url === '/employer-ecard'}">
      <a class="nav-link" routerLink="/employer-ecard">
        <!-- <img src="../../../assets/img/employee/id_card.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-folder-download menu-icon"></i>
        <span class="menu-title">Download E-Card</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'" [ngClass]="{'active': url === '/dynamic-fields'}" style="display: none;">
      <a routerLink="/dynamic-fields" id="dynamic-fields" class="nav-link">
        <i class="mdi mdi-more menu-icon"></i>
        <span class="menu-title">Dynamic Fields</span>
      </a>
    </li>

    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin' && claim_status == 1" [ngClass]="{'active': url === '/claim-status'}">
      <a routerLink="/claim-status" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Claim Status Master</span>
      </a>
    </li> -->

    <!-- <li class="nav-item" *ngIf="user_role == 'brokeradmin' && broker_insurance_company == 1" [ngClass]="{'active': url === '/broker-insurance-company'}">
      <a routerLink="/broker-insurance-company" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Insurance Company</span>
      </a>
    </li>     -->

    <!-- <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="user_role == 'brokeradmin' && broker_tpa == 1" [ngClass]="{'active': url === '/admin-tpa-details'}">
      <a routerLink="/admin-tpa-details" class="nav-link">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">TPA Details</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && e_card_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isECardOpen" (click)="toggleECardCollapse()" href="#ecard" aria-expanded="false" aria-controls="ecard">
        <i class="mdi mdi-account-card-details menu-icon"></i>
        <!-- <img src="../../../assets/img/employee/id_card.png" alt="Icon" class="menuicon"> -->
        <span class="menu-title">E-Card</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isECardOpen, 'show': isECardOpen}" id="ecard">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/broker-ecard-upload'}" *ngIf="broker_e_card_upload == 1">
            <a routerLink="/broker-ecard-upload" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/upload_ecard.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-edit menu-icon"></i>
              <span class="menu-title">Upload E-Card Offline </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/ecard-file-upload'}" *ngIf="e_card_file_upload == 1">
            <a routerLink="/ecard-file-upload" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/ecard_file_upload.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-alert menu-icon"></i>
              <span class="menu-title">E-Card File Upload Offline </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/broker-ecard'}" *ngIf="broker_e_card == 1">
            <a routerLink="/broker-ecard" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/download_ecard.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-folder-download menu-icon"></i>
              <span class="menu-title">Download E-Card </span>
            </a>
          </li>       
        </ul>
      </div>
    </li> 

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && wellness_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isWellnessOpen" (click)="toggleWellnessCollapse()" href="#wellness" aria-expanded="false" aria-controls="wellness">
        <i class="mdi mdi-star-circle menu-icon"></i>
        <span class="menu-title">Wellness</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isWellnessOpen, 'show': isWellnessOpen}" id="wellness">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/wellness'}" *ngIf="wellness == 1">
            <a routerLink="/wellness" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/wellness_onboard.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-marker-check menu-icon"></i>
              <span class="menu-title">Wellness Onboard </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/wellness-benefit'}" *ngIf="wellness_benefit == 1">
            <a routerLink="/wellness-benefit" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/wellness_benefit.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-tag-heart menu-icon"></i>
              <span class="menu-title">Wellness Benefit </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/employer-wellness-benefit'}" *ngIf="emp_wellness_benefit == 1">
            <a routerLink="/employer-wellness-benefit" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/wellness_emp.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-heart-box menu-icon"></i>
              <span class="menu-title">Wellness Benefit Client </span>
            </a>
          </li>       
        </ul>
      </div>
    </li> 

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && network_hospital_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isNetworksOpen" (click)="toggleNetworksCollapse()" href="#networkhospital" aria-expanded="false" aria-controls="networkhospital">
        <i class="mdi mdi-hospital menu-icon"></i>
        <span class="menu-title">Network Hospital</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isNetworksOpen, 'show': isNetworksOpen}" id="networkhospital">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/network-hospital'}" *ngIf="network_hospital == 1">
            <a routerLink="/network-hospital" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/network_hospital.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-hospital-marker menu-icon"></i>
              <span class="menu-title">Network Hospital </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/broker-networks'}" *ngIf="broker_networks == 1">
            <a routerLink="/broker-networks" class="nav-link"> 
              <!-- <img src="../../../assets/img/common icons/networks.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-plus-network menu-icon"></i>
              <span class="menu-title">Networks </span>
            </a>
          </li> 
                 
        </ul>
      </div>
    </li> 
        
    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && ancillary_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isAncillaryOpen" (click)="toggleAncillaryCollapse()" href="#ancillary" aria-expanded="false" aria-controls="ancillary">
        <i class="mdi mdi-webpack menu-icon"></i>
        <span class="menu-title">Ancillary</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isAncillaryOpen, 'show': isAncillaryOpen}" id="ancillary">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/broker-ancillary'}" *ngIf="broker_ancillary == 1">
            <a routerLink="/broker-ancillary" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/ancillary.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-plex menu-icon"></i>
              <span class="menu-title">Ancillary Onboard </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/broker-ancillary-details'}" *ngIf="broker_ancillary_details == 1">
            <a routerLink="/broker-ancillary-details" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/ancillary_details.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-image-filter-none menu-icon"></i>
              <span class="menu-title">Ancillary Details </span>
            </a>
          </li> 
                 
        </ul>
      </div>
    </li> 

    <li class="nav-item" *ngIf="user_role == 'brokeradmin'&& tpa_trigger_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isTPAOpen" (click)="toggleTPACollapse()" href="#tpa-trigger" aria-expanded="false" aria-controls="ancillary">
        <i class="mdi mdi-multiplication-box menu-icon"></i>
        <span class="menu-title">TPA Trigger</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isTPAOpen, 'show': isTPAOpen}" id="tpa-trigger">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/broker-tpa-trigger'}" *ngIf="broker_tpa_trigger == 1">
            <a routerLink="/broker-tpa-trigger" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_api.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-checkbox-multiple-blank menu-icon"></i>
              <span class="menu-title">TPA Trigger API </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/broker-tpa-trigger-logs'}" *ngIf="broker_tpa_trigger_logs == 1">
            <a routerLink="/broker-tpa-trigger-logs" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_logs.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              <span class="menu-title">TPA Trigger Logs </span>
            </a>
          </li> 
                 
        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && reports_module == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isReportsOpen" (click)="toggleReportsCollapse()" href="#reports" aria-expanded="false" aria-controls="reports">
        <i class="mdi mdi-file-document menu-icon"></i>
        <span class="menu-title">Reports</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isReportsOpen, 'show': isReportsOpen}" id="reports">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/broker-report'}" *ngIf="broker_report == 1">
            <a routerLink="/broker-report" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_api.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-file menu-icon"></i>
              <span class="menu-title">Enrollment Report </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/flex-report'}" *ngIf="flex_report == 1">
            <a routerLink="/flex-report" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_logs.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              <span class="menu-title">Flex Report </span>
            </a>
          </li> 
          <li class="nav-item" [ngClass]="{'active': url === '/cd-updation'}" *ngIf="cd_updation == 1">
            <a routerLink="/cd-updation" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/tpa_logs.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              <span class="menu-title">CD Updation </span>
            </a>
          </li> 

        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="user_role == 'brokeradmin' && survey_gmc_policy_details == 1" [ngClass]="{'active': url === '/survey-gmc-policy-details'}">
      <a class="nav-link" routerLink="/survey-gmc-policy-details">
        <i class="mdi mdi-collage menu-icon"></i>
        <span class="menu-title">Survey</span>
      </a>
    </li>

    <!-------------------- Employee User ---------------------->
    <!-- <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeruser' && flex_configurator_dashboard == 1" [ngClass]="{'active': url === '/flex-configurator-dashboard'}">
      <a class="nav-link" routerLink="/flex-configurator-dashboard">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Home</span>
      </a>
    </li>  -->

    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/employeruser-dashboard'}">
      <a class="nav-link" routerLink="/employeruser-dashboard">
        <!-- <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-view-dashboard menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>        

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser' && employeruser_flex_plan == 1" [ngClass]="{'active': url === '/flex-plan'}">
      <a class="nav-link" routerLink="/flex-plan">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Flex Plan</span>
      </a>
    </li> -->
    
    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/dashboard.png" alt="Icon" class="menuicon">
        <span class="menu-title">Dashboard</span>
      </a>
    </li> -->

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser' && enrollment == 1" [ngClass]="{'active': url === '/employeruser-dashboard/family-enrollment'}">
      <a class="nav-link" routerLink="/employeruser-dashboard/family-enrollment">
        <img src="../../../assets/img/employee/enrollment.png" alt="Icon" class="menuicon">
        <span class="menu-title">Enrollment</span>
      </a>
    </li> -->

    <!-- <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <img src="../../../assets/img/employee/application_form.png" alt="Icon" class="menuicon">
        <span class="menu-title">Claims</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="user_role == 'employeruser' ">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isClaimsOpen" (click)="toggleClaimsCollapse()" href="#claims" aria-expanded="false" aria-controls="claims">
        <i class="menu-icon mdi mdi-clipboard-check"></i>
        <span class="menu-title">Claims</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isClaimsOpen, 'show': isClaimsOpen}" id="claims">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/employee-intimate-claims'}">
            <a routerLink="/employee-intimate-claims" class="nav-link">
              <i class="mdi mdi-note-plus menu-icon"></i>
              <span class="menu-title">Intimate Claim</span>             
            </a>
          </li>

          <li class="nav-item" [ngClass]="{'active': url === '/employee-claim-portal'}">
            <a class="nav-link" routerLink="/employee-claim-portal">
              <i class="mdi mdi-server menu-icon"></i>
              <span class="menu-title">Portal Claims</span>
            </a>
          </li>

          <li class="nav-item" [ngClass]="{'active': url === '/overall-claims'}">
            <a class="nav-link" routerLink="/overall-claims">
              <i class="mdi mdi-receipt menu-icon"></i>
              <span class="menu-title">Overall Claims</span>
            </a>
          </li>

        </ul>
      </div>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/contact-us-info'}">
      <a class="nav-link" routerLink="/contact-us-info">
        <!-- <img src="../../../assets/img/employee/contact_us.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-deskphone menu-icon"></i>
        <span class="menu-title">Contact Us</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/employee-ecard'}">
      <a class="nav-link" routerLink="/employee-ecard">
        <!-- <img src="../../../assets/img/employee/id_card.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-account-card-details menu-icon"></i>
        <span class="menu-title">Download E-Card</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/networks'}">
      <a class="nav-link" routerLink="/networks">
        <!-- <img src="../../../assets/img/employee/hospital.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-hospital-building menu-icon"></i>
        <span class="menu-title">Network Hospital</span>
      </a>
    </li> 

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === ''}" style="pointer-events: none;">
      <a class="nav-link" routerLink="">
        <!-- <img src="../../../assets/img/employee/help.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-help-circle-outline menu-icon"></i>
        <span class="menu-title">Help</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="user_role == 'employeruser'" [ngClass]="{'active': url === '/form-center'}">
      <a class="nav-link" routerLink="/form-center">
        <!-- <img src="../../../assets/img/employee/form.png" alt="Icon" class="menuicon"> -->
        <i class="mdi mdi-file-check menu-icon"></i>
        <span class="menu-title">Form Center</span>
      </a>
    </li>        
    
    

    <!------------------- HR Info -------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'commonhr' && hr_info_dashboard == 1" [ngClass]="{'active': url === '/hr-info-dashboard'}">
      <a class="nav-link" routerLink="/hr-info-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!------------------ Client ----------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'client' && Client_dashboard == 1" [ngClass]="{'active': url === '/Client-dashboard'}">
      <a class="nav-link" routerLink="/Client-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>



    <!------------------- Superadmin ---------------------->
    <li class="nav-item" style="margin-top: 20px;" *ngIf="user_role == 'superadmin' && superadminDashboard == 1" [ngClass]="{'active': url === '/SuperAdmin-dashboard'}">
      <a class="nav-link" routerLink="/SuperAdmin-dashboard">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin' && management == '1'">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isMgmtOpen" (click)="toggleMgmtCollapse()" href="#managelist" aria-expanded="false" aria-controls="managelist">
        <i class="menu-icon mdi mdi-settings"></i>
        <span class="menu-title">Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isMgmtOpen, 'show': isMgmtOpen}" id="managelist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-theme'}" *ngIf="manage_theme == 1">
            <a routerLink="/admin-manage-theme" class="nav-link"> 
              <!-- <img src="../../../assets/img/common icons/theme.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-theme-light-dark menu-icon"></i>
              <span class="menu-title">Manage Theme</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-news'}" *ngIf="manage_news == 1">
            <a routerLink="/admin-manage-news" class="nav-link">
              <i class="mdi mdi-newspaper menu-icon"></i>
              <span class="menu-title">Manage News</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-annoucements'}" *ngIf="manage_annoucements == 1">
            <a routerLink="/admin-manage-annoucements" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/annoucement.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-bullhorn menu-icon"></i>
              <span class="menu-title">Manage Annoucements</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-country'}" *ngIf="manage_country == '1'">
            <a routerLink="/admin-manage-country" class="nav-link">
              <i class="mdi mdi-earth menu-icon"></i>
              <span class="menu-title">Manage Country</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-state'}" *ngIf="manage_state == 1">
            <a routerLink="/admin-manage-state" class="nav-link">
              <i class="mdi mdi-flag-outline menu-icon"></i>
              <span class="menu-title">Manage State</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-city'}" *ngIf="manage_city == 1">
            <a routerLink="/admin-manage-city" class="nav-link">
              <i class="mdi mdi-city menu-icon"></i>
              <span class="menu-title">Manage City</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-industry'}" *ngIf="manage_industry == 1">
            <a routerLink="/admin-manage-industry" class="nav-link">
              <i class="mdi mdi-factory menu-icon"></i>
              <span class="menu-title">Manage Industry</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-user-types'}" *ngIf="manage_user_types == 1">
            <a routerLink="/admin-manage-user-types" class="nav-link">
              <i class="mdi mdi-account-settings menu-icon"></i>
              <span class="menu-title">Manage User Types</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-plan'}" *ngIf="manage_plan == 1">
            <a routerLink="/admin-manage-plan" class="nav-link">
              <i class="mdi mdi-calendar-blank menu-icon"></i>
              <span class="menu-title">Manage Plan</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-tpa-details'}" *ngIf="tpa_details == 1">
            <a routerLink="/admin-tpa-details" class="nav-link">
              <i class="mdi mdi-settings-box menu-icon"></i>
              <span class="menu-title">TPA Details</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-insurance-company-insurer'}" *ngIf="insurance_company_insurer == 1">
            <a routerLink="/admin-insurance-company-insurer" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/master_broker.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-multiple menu-icon"></i>
              <span class="menu-title">Insurance Company</span>
            </a>
          </li>

        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="user_role == 'superadmin' && masters == 1">
      <a class="nav-link" data-bs-toggle="collapse" [class.collapsed]="!isMastersOpen" (click)="toggleMastersCollapse()" href="#masterlist" aria-expanded="false" aria-controls="masterlist">
        <i class="menu-icon mdi mdi-apps"></i>
        <span class="menu-title">Masters</span>
        <i class="menu-arrow"></i>
      </a>
      <div [ngClass]="{'collapse': !isMastersOpen, 'show': isMastersOpen}" id="masterlist">
        <ul class="nav flex-column sub-menu">

          <li class="nav-item" [ngClass]="{'active': url === '/admin-mastermodules'}" *ngIf="admin_mastermodules == 1">
            <a routerLink="/admin-mastermodules" id="admin-mastermodules" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/master_module.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-view-module menu-icon"></i>
              <span class="menu-title">Master Modules </span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': url === '/admin-manage-master-broker'}" *ngIf="master_broker == 1">
            <a routerLink="/admin-manage-master-broker" id="admin-manage-master-broker" class="nav-link">
              <!-- <img src="../../../assets/img/common icons/master_broker.png" alt="Icon" class="menuicon"> -->
              <i class="mdi mdi-account-multiple menu-icon"></i>
              <span class="menu-title">Master Broker</span>
            </a>
          </li>

        </ul>
      </div>
    </li>
    
    

  </ul>
</nav>
